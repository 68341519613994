import React,  { Component } from 'react';
import SectionTitle from "../SectionTitle/SectionTitle";
import constitution from "../../documents/ChiDAES-Constitution.pdf";
import membershipForm from "../../documents/ChiDAES-Membership_Form.pdf";
import grantRequestForm from "../../documents/ChiDAES-Grant_Request_Form.pdf";

class GetInvolved extends Component {
	render () {
		return (
			<div>
				<div className="page-header getInvolved-header">
					<div className="overlay">
						<h1>Get Involved</h1>
					</div>
				</div>
				<div className="content">
					<SectionTitle title="Become a Member" />
					<p>Interested in joining ChiDAES Canada? To become a member, please download and submit our membership form. For details regarding our membership policy, please review Section 2 of our <a rel="noopener noreferrer" target="_blank" href={constitution}>constitution</a>.</p>
					<p>Download <a rel="noopener noreferrer" target="_blank" href={membershipForm}>ChiDAES Canada membership form</a>.</p>
					
					<SectionTitle title="Request a Grant" />
					<p>Could your organization use the support of ChiDAES Canada? To request a grant, please download and submit our grant request form.</p>
					<p>Download <a rel="noopener noreferrer" target="_blank" href={grantRequestForm}>ChiDAES Canada grant request form</a>.</p>
					
					<SectionTitle title="Donate in Canada" />
					<p>Please contact us if you'd like to make a donation to ChiDAES Canada.</p>
					<h4 className="mt-10">Mailing Address</h4>
					<p>12 Calgary Gardens<br />
					Woodbridge, Ontario, Canada<br />
					L4L 8B2</p>
					<h4>Email</h4>
					<p><a rel="noopener noreferrer" href="mailto:ChiDAES.Canada@gmail.com">ChiDAES.Canada@gmail.com</a></p>
				</div>
			</div>
		);
	}
}

export default GetInvolved;