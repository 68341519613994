import React,  { Component } from 'react';
import './About.scss';
import VNonisappu from '../../images/committee-members/VNonisappu.jpeg';
import TKamalachandran from '../../images/committee-members/TKamalachandran.jpg';
import PKanagalingam from '../../images/committee-members/PKanagalingam.jpg';
import WChaseendran from '../../images/committee-members/WChaseendran.jpg';
import PPuvanesarajah from '../../images/committee-members/PPuvanesarajah.jpeg';
import JManickam from '../../images/committee-members/JManickam.jpg';
import SSivalingam from '../../images/committee-members/SSivalingam.jpg';
import VKanapathipillai  from '../../images/committee-members/VKanapathipillai.jpeg';
import SMarkandulingam from '../../images/committee-members/SMarkandulingam.jpeg';
import GMurugesu from '../../images/committee-members/GMurugesu.jpeg';
import DKhobalakrishnan from '../../images/committee-members/DKhobalakrishnan.jpeg';
import VThambirajah from '../../images/committee-members/VThambirajah.jpeg';
import MManickam from '../../images/committee-members/MManickam.jpeg';
import ANagamany from '../../images/committee-members/ANagamany.jpg';
import RAlexandar from '../../images/committee-members/RAlexandar.jpg';
import KKathirgamathamby from '../../images/committee-members/KKathirgamathamby.jpg';
import KVijayaretnam from '../../images/committee-members/KVijayaretnam.jpeg';
import AVaradarasa from '../../images/committee-members/AVaradarasa.jpeg';
import RVeerasingham from '../../images/committee-members/RVeerasingham.jpeg';

class Committee extends Component {
	render () {
		return (
			<div>
				<div className="page-header about-header">
					<div className="overlay">
						<h1>About</h1>
						<h2>Committee Members</h2>
					</div>
				</div>
				<div className="content">
					<div className="committee">
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={VNonisappu} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">President</span><br />
							Vijayaretnam Nonisappu</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={TKamalachandran} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Vice-President</span><br />
							Thiyagarajah Kamalachandran</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={PKanagalingam} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Vice-President</span><br />
							Puvanesarajah Kanagalingam</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={WChaseendran} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Secretary</span><br />
							Werasingum Chaseendran</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={PPuvanesarajah} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Assistant Secretary</span><br />
							Piravinth Puvanesarajah</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={JManickam} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Treasurer</span><br />
							Jeeva Manickam</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={SSivalingam} alt="committee member - president"/>
							</div>
							<p><span className="eyebrow">Assistant Treasurer</span><br />
							Sritharan Sivalingam</p>
						</div>
					</div>
					<div className="section-break"></div>
					<h3 className="text-center">Committee Members</h3>
					<div className="small-section-break"></div>
					<div className="committee">
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={VKanapathipillai} alt="committee member - president"/>
							</div>
							<p>Visualingam Kanapathipillai</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={SMarkandulingam} alt="committee member - president"/>
							</div>
							<p>Sundararajan Markandulingam</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={GMurugesu} alt="committee member - president"/>
							</div>
							<p>Gunasegaram Murugesu</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={DKhobalakrishnan} alt="committee member - president"/>
							</div>
							<p>Damodaram Khobalakrishnan</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={VThambirajah} alt="committee member - president"/>
							</div>
							<p>Vasanthakumar Thambirajah</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={MManickam} alt="committee member - president"/>
							</div>
							<p>Mahendran Manickam</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={ANagamany} alt="committee member - president"/>
							</div>
							<p>Arulraj Nagamany</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={RAlexandar} alt="committee member - president"/>
							</div>
							<p>Rupert Francis Alexandar</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={KKathirgamathamby} alt="committee member - president"/>
							</div>
							<p>Karunailingam Kathirgamathamby</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={KVijayaretnam} alt="committee member - president"/>
							</div>
							<p>Kuboshanth Vijayaretnam</p>
						</div>
					</div>
					<div className="section-break"></div>
					<h3 className="text-center">Patrons</h3>
					<div className="small-section-break"></div>
					<div className="committee">
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={AVaradarasa} alt="committee member - president"/>
							</div>
							<p>Dr. Arulanandam Varadarasa</p>
						</div>
						<div className="committee-member">
							<div className="com-photo-wrapper">
								<img src={RVeerasingham} alt="committee member - president"/>
							</div>
							<p>Ravindran Veerasingham</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Committee;