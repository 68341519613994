import React, { Component }  from 'react';
import { Route } from "react-router-dom";
import { CSSTransitionGroup } from 'react-transition-group'; 
import './App.scss';
import Nav from "./components/Nav/Nav.js";
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import Committee from "./components/About/Committee.js";
import Events from "./components/Events/Events.js";
import PastEvents from "./components/Events/PastEvents.js";
import Projects from "./components/Projects/Projects.js";
import Gallery from "./components/Projects/Gallery.js";
import GetInvolved from "./components/GetInvolved/GetInvolved.js";
import Contact from "./components/Contact/Contact.js";
import Footer from "./components/Footer/Footer.js";


import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700,800', 'Roboto Condensed:300,400,700', 'PT Sans Narrow:400,700']
  }
});


const routes = [
  { path: '/', location: window.location, exact: true, key: 'Home', Component: Home },
  { path: '/about', location: window.location, exact: true, key: 'About', Component: About },
  { path: '/about/committee-members', location: window.location, exact: true, key: 'Committee', Component: Committee },
  { path: '/events', location: window.location, exact: true, key: 'Events', Component: Events },
  { path: '/past-events', location: window.location, exact: true, key: 'PastEvents', Component: PastEvents },
  { path: '/projects', location: window.location, exact: true, key: 'Projects', Component: Projects },
  { path: '/gallery', location: window.location, exact: false, key: 'Gallery', Component: Gallery },
  { path: '/get-involved', location: window.location, exact: true, key: 'GetInvolved', Component: GetInvolved },
  { path: '/contact', location: window.location, exact: true, key: 'Contact', Component: Contact },
]


class App extends Component {
  render() {
    return (
        <div className="App">
          <Nav />
          {routes.map(({ path, location, exact, key, Component }) => (
            <Route exact={exact}  location={location} key={key} path={path}>
                <CSSTransitionGroup
                  transitionName="fade"
                  transitionAppear={true}
                  transitionAppearTimeout={200}
                  transitionEnter={true}
                  transitionLeave = {true}
                  transitionEnterTimeout={200}
                  transitionLeaveTimeout={200}>
                  <Component />
                </CSSTransitionGroup>
            </Route>
          ))}
          <Footer />
        </div>
    );
  }
}

export default App;

