import React,  { Component } from 'react';
import './SectionTitle.scss';

class SectionTitle extends Component {

	render () {
		return (
				<div className="h3-w-line">
					<div className="h3-line"></div>
					<div className="h3-text">
						<h3>{this.props.title}</h3>
					</div>
				</div>	
		);
	}
}

export default SectionTitle;