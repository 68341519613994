import React,  { Component } from 'react';
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

class Events extends Component {
	render () {
		return (
			<div>
				<div className="page-header events-header">
					<div className="overlay">
						<h1>Events</h1>
					</div>
				</div>
				<div className="content">
					<SectionTitle title="Upcoming Events" />
					<div className="text-box">
						<h4>ChiDAES Canada's Annual Tree Planting Event</h4>
						<p><strong>Date:</strong> September 19, 2020 from 10:00AM to 12:30PM <br />
						<strong>Location:</strong> TBD</p>
					</div>
					<SectionTitle title="Past Events" />
					<div className="cols">
						<div className="photo-box-wrapper">
							<div className="photo-box treeplanting"></div>
							<div className="overlay">
								<div className="photo-box-subtitle">ChiDAES Canada's</div>
								<div className="photo-box-title">Annual Tree Planting Event</div>
								
								<div className="section-break"></div>
							</div>
							<div className="photo-box-caption">
								In 2019, we planted 180 trees in Aquinas Park, Mississauga.&nbsp;
								<a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/pg/chidaes/photos/?tab=album&album_id=2356928207924007">See photos on Facebook</a>
							</div>
						</div>
						<div className="photo-box-wrapper">
							<div className="photo-box gathering"></div>
							<div className="overlay">
								<div className="photo-box-subtitle">ChiDAES Canada's</div>
								<div className="photo-box-title">Annual Gathering</div>
								<div className="section-break"></div>
							</div>
							<div className="photo-box-caption">
								Our 2018 gathering was held at Stephen's Church, North York.&nbsp;
								<a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=2051190048497826">See photos on Facebook</a>
							</div>
						</div>
					</div>
					<Link to="/past-events" className="center-link">See all past events</Link>
				</div>
			</div>
		);	
	}
}

export default Events;