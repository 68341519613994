import React,  { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group'; 
import SectionTitle from "../SectionTitle/SectionTitle";
import './Gallery.scss';
import data from '../../json/projects.json';

let xDown = null; 
let xUp = null;  

class Gallery extends Component {
	constructor(props) {
	    super(props);

	    const hashId = parseInt(window.location.hash.slice(1));
	    let p = {};

	    data.Projects.forEach((key, index) => {
		  if (parseInt(key.id) === hashId){
		  	p = key;
		  }
		});

	    this.state = {
	   	  project: p,
	      photoArray: p.images,
	      photo: '',
	      photoNext: '',
	      modalOpen: false
		};

		this.handleBwd = this.handleBwd.bind(this);
		this.handleFwd = this.handleFwd.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);
	}


	openModal(i) {
		this.setState({
			photo: this.state.photoArray[i],
			modalOpen: true
		});
		document.body.style.overflow = 'hidden';
	}

	handleClose(e) {
		if (!e.target.classList.contains('gal-image') && !e.target.classList.contains('fa-chevron-right') && !e.target.classList.contains('fa-chevron-left')){
			this.closeModal();
		}
	}

	closeModal(){
		this.setState({
			photo: '',
			photoNext: '',
			modalOpen: false
		});
		document.body.style.overflow = 'auto';
	}

	handleBwd(e) {
		if ( !this.state.photoNext) {
			let current = this.state.photoArray.indexOf(this.state.photo);
			if (current === 0)
				current = this.state.photoArray.length;
			this.setState({
				photoNext: this.state.photoArray[current-1],
				photo: ''
			});
		}
		else {
			let current = this.state.photoArray.indexOf(this.state.photoNext);
			if (current === 0)
				current = this.state.photoArray.length;
			this.setState({
				photo: this.state.photoArray[current-1],
				photoNext: ''
			});
		}
	}

	handleFwd(e) {
		if ( !this.state.photoNext) {
			let current = this.state.photoArray.indexOf(this.state.photo);
			if (current === this.state.photoArray.length - 1)
				current = -1;
			this.setState({
				photoNext: this.state.photoArray[current+1],
				photo: ''
			});
		}
		else {
			let current = this.state.photoArray.indexOf(this.state.photoNext);
			if (current === this.state.photoArray.length - 1)
				current = -1;
			this.setState({
				photo: this.state.photoArray[current+1],
				photoNext: ''
			});
		}
	}

	handleKeyPress(e) {
		e = e || window.event;

		if (this.state.modalOpen) {
		    if (e.keyCode === 37) {
		       this.handleBwd();
		    }
		    else if (e.keyCode === 39) {
		       this.handleFwd();
			}
			else if (e.keyCode === 27)
				this.closeModal();
		}
	}

	handleTouchStart(e) {
	    const firstTouch = e.touches || e.originalEvent.touches;                                      
	    xDown = firstTouch[0].clientX;                                                                           
	}                                                

	handleTouchMove(e) {
    	if ( !xDown ) 
        	return;

	    xUp = e.touches[0].clientX;                                                                              
	};

	handleTouchEnd(e) {
		if ( !xDown || !xUp ) 
        	return;

		let xDiff = xDown - xUp;

	    if ( xDiff > 0 ) {
	        this.handleFwd();
        } else {
            this.handleBwd();
        }                       

        xUp = null;
	    xDown = null; 
	}

	componentDidMount(){
    	document.addEventListener("keydown", this.handleKeyPress, false);
    	document.addEventListener('touchstart', this.handleTouchStart, false);        
		document.addEventListener('touchmove', this.handleTouchMove, false);
		document.addEventListener('touchend', this.handleTouchEnd, false);
	}
	
	componentWillUnmount(){
	    document.removeEventListener("keydown", this.handleKeyPress, false);
	}

	render () {
		return (
			<div>
				<div className="page-header projects-header">
					<div className="overlay">
						<h1>Projects</h1>
					</div>
				</div>
				<div className="content">
					<Link to="/projects" className="back-link">Back to Projects</Link>
					<SectionTitle title={this.state.project.name} />
					{this.state.project.desc && <div className="text-box">
						<div className="eyebrow">{this.state.project.date}</div>
						<p>{this.state.project.desc}</p>
						<ul>
							{this.state.project.support.map((s, i) => ( 
								<li key={i}>{s}</li>
							))}
						</ul>
					</div>}
					{ this.state.photoArray && <div className="gal-thumbnails">
		                {this.state.photoArray.map((p, i) => (
		                	<div className="gal-thumbnail-wrapper">
		                		<img key={i} className="gal-thumbnail" src={p.image} alt="thumbnail" onClick={() => this.openModal(i)} />
		                	</div>
		                ))}
		            </div>
		        	}
		        	{ !this.state.photoArray && <div>
		        		No photos at the moment. 
		        	</div>
		        	}
                </div>
                <CSSTransitionGroup
					transitionName="fade"
					transitionEnter={true}
					transitionLeave={true}
					transitionEnterTimeout={200}
					transitionLeaveTimeout={200}>
	                { this.state.modalOpen && <div className="gallery-full" onClick={this.handleClose}>
	                	<div className="gal-photo-wrapper">
	                		<i className="fas fa-times" onClick={this.handleClose}></i>
	                		<CSSTransitionGroup
								transitionName="fade"
								transitionEnter={true}
								transitionLeave={true}
								transitionEnterTimeout={200}
								transitionLeaveTimeout={200}>
		                		{ this.state.photo && <div className="gal-image-wrapper">
		                			<img key={this.state.photo} className="gal-image" src={this.state.photo.image} alt="gallery"/> 
		                		</div>
		                		}
	                		</CSSTransitionGroup>
	                		<CSSTransitionGroup
								transitionName="fade"
								transitionEnter={true}
								transitionLeave={true}
								transitionEnterTimeout={200}
								transitionLeaveTimeout={200}>
		                		{ this.state.photoNext && <div className="gal-image-next-wrapper">
		                			<img key={this.state.photoNext} className="gal-image gal-image-next" src={this.state.photoNext.image} alt="gallery"/> 
		                		</div>
		                		}
	                		</CSSTransitionGroup>
	                		<div className="gal-arrow gal-left">
		                		<i className="fas fa-chevron-left" onClick={this.handleBwd}></i>
		                	</div>
		                	<div className="gal-arrow gal-right">
		                		<i className="fas fa-chevron-right" onClick={this.handleFwd}></i>
		                	</div>
		                </div>
	                </div>}
            	</CSSTransitionGroup>
			</div>
		)
	}
}

export default Gallery;