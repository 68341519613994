import React,  { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group'; 
import projectsList from "../../documents/ChiDAES-Brochure_2020.pdf";
import data from '../../json/projects.json';

const projects = data.Projects;
let projectsSome = projects.slice(0, projects.length-2);
let projectsAll = projects.slice(projects.length-2, projects.length);

class Projects extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      seeAll: false
		};
		this.handleSeeAll = this.handleSeeAll.bind(this);
		this.showGallery = this.showGallery.bind(this);
	}

	handleSeeAll(e) {
		this.setState({
			seeAll: true
		});
	}

	showGallery(){
		console.log('test');
	}

	render () {
		return (
			<div>
				<div className="page-header projects-header">
					<div className="overlay">
						<h1>Projects</h1>
					</div>
				</div>
				<div className="content">
				<div className="projectLink"><strong>Please find a complete list of all our projects <a rel="noopener noreferrer" target="_blank" href={projectsList}><strong>here</strong></a>.</strong></div>
				<div className="project-wrapper">
                {projectsSome.map((p, i) => (
                	<div key={i} className={projectsSome.length%2 &&  i===projectsSome.length-1? 'full-width' : ''}>
	               		<div className="project-box">
	               			<Link to={`/gallery#${p.id}`}>
		                		{p.cover && 
		                			<div className="project-image" style={{ backgroundImage: `url(/projects/cover-photos/${p.cover})` }}></div>
		                		}
		                		<div className="project-text"> 
			                		<div className="eyebrow">{p.date}</div>
			                		<h4 className="mt-0">{p.name}</h4>
			                		<p>{p.desc}</p>
			                		<div className="link">See more</div>
				                </div>
				            </Link>
		                </div>
	                </div>	
                ))}
                </div>

                <CSSTransitionGroup
					transitionName="fade"
					transitionEnter={true}
					transitionLeave={false}
					transitionEnterTimeout={200}>
					<div className="project-wrapper">
	                {this.state.seeAll && projectsAll.map((p, i) => (
	                	<div key={i}>
		                	<div className="project-box">
		                		{p.cover && 
		                			<div className="project-image" style={{ backgroundImage: `url(/projects/cover-photos/${p.cover})` }}></div>
		                		}
		                		<div className="project-text"> 
			                		<div className="eyebrow">{p.date}</div>
			                		<h4 className="mt-0">{p.name}</h4>
			                		<p>{p.desc}</p>
			                		<ul>
			                			{p.support.map((s, i) => ( 
			                				<li key={i}>{s}</li>
			                			))}
			                		</ul>
			                		{p.images && <Link to={`/gallery#${p.id}`}>See photos</Link>}
				                </div>
		                	</div>
	                	</div>	
	                ))}
	                </div>
                </CSSTransitionGroup>

                {!this.state.seeAll && <div className="center-link down-arrows" onClick={this.handleSeeAll}>See all projects</div>}
				</div>
			</div>
		)
	}
}

export default Projects;