import React,  { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.scss';
import logo from '../../images/logo/logo.png';

class Footer extends Component {
	render () {
		return (
			<div className="footer">
				<div className="content">
					<div className="footer-wrapper cols">
						<div className="footer-left">
							<Link to="/">
								<div className="logo-wrapper">
									<img src={logo} className="logo" alt="logo"/>
								</div>
								<div className="logo-name">
									<span className="logo-acc">ChiDAES</span>
									<span className="logo-full">Children Development Association <br />
									of Eastern Sri Lanka</span>
								</div>
							</Link>
							<div className="footer-col-nav">
								<Link to="/about">About</Link>
								<span>•</span>
								<Link to="/events">Events</Link>
								<span>•</span>
								<Link to="/projects">Projects</Link>
								<span>•</span>
								<Link to="/get-involved">Get Involved</Link>
								<span>•</span>
								<Link to="/contact">Contact</Link>
							</div>
						</div>
						<div className="footer-right">
							<div className="social">
								<span>Follow us on</span>
								<a rel="noopener noreferrer" href="https://www.facebook.com/chidaes" target="_blank" ><i className="fab fa-facebook-square"></i></a>
							</div>
						</div>
					</div>
					<p className="text-small copyright">Copyright © 2020 Children Development Association of Eastern Sri Lanka – A Canadian Foundation</p>
					<p className="text-small credit">Designed and developed by <a rel="noopener noreferrer" target="_blank" href="http://www.meetimasha.com/">Imasha Sembukutti</a></p>
				</div>
			</div>
		);
	}
}

export default Footer;