import React,  { Component } from 'react';
import SectionTitle from "../SectionTitle/SectionTitle";

class PastEvents extends Component {
	render () {
		return (
			<div>
				<div className="page-header events-header">
					<div className="overlay">
						<h1>Events</h1>
					</div>
				</div>
				<div className="content">
					<SectionTitle title="2019 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 8th Tree Planting Event</h4>
						<p><strong>Date:</strong>  June 01, 2019 from 9:30AM to 11:30AM<br />
						<strong>Location:</strong> Aquinas Park, 3400 Aquinas Avenue, Mississauga L5M 7L2</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/pg/chidaes/photos/?tab=album&album_id=2356928207924007">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2018 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 7th Tree Planting Event</h4>
						<p><strong>Date:</strong> June 02, 2018 from 9:30AM to 11:30AM<br />
						<strong>Location:</strong> Swinbourne Meadows, 1129 Swinbourne Drive, Mississauga L5V 1B9</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=2090961651187332">See photos on Facebook</a></p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong>  Feb 18, 2018 at 6:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=2051190048497826">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2017 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 6th Tree Planting Event</h4>
						<p><strong>Date:</strong> May 13, 2017 from 9:30AM to 11:30AM<br />
						<strong>Location:</strong> Max Ward Park, 2380 Matheson Blvd East, Mississauga L4W 5G9</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=1896271343989698">See photos on Facebook</a></p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> Feb 25, 2017 at 6:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=1855497634733736">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2016 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 5th Tree Planting Event</h4>
						<p><strong>Date:</strong> May 28, 2016 from 9:00AM to 11:00AM<br />
						<strong>Location:</strong> Richard Jones Park, 181 Whitchuch Mews, Mississauga L5A 4B3</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=1726504407633060">See photos on Facebook</a></p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> Feb 20, 2016 at 6:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/photos/?tab=album&album_id=1686085231674978">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2015 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> Feb 15, 2015 at 7:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662635970686571">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2013 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 4th Tree Planting Event</h4>
						<p><strong>Date:</strong> June 8, 2013 from 9:00AM to 11:00AM<br />
						<strong>Location:</strong> Garnetwood Park, Burnhamthorpe Road, Mississauga L5N 7G8</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662636090686559">See photos on Facebook</a></p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> Oct 13, 2013 at 7:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662635977353237">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2012 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 3rd Tree Planting Event</h4>
						<p><strong>Date:</strong> June 2, 2012 from 9:00AM to 11:00AM<br />
						<strong>Location:</strong> Stonewood Park, 7348 Sandhurst Drive, Mississauga L5N 7G8</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662636097353225">See photos on Facebook</a></p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> May 26, 2012 at 7:00PM<br />
						<strong>Location:</strong> St. Stephen's Church, 2259 Jane Street, North York, ON M3M 1A6</p>
						<p><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662636104019891">See photos on Facebook</a></p>
					</div>

					<SectionTitle title="2011 Events" />
					<div className="text-box">
						<h4 class="mt-0">University Entrants from Hari Illam</h4>
						<p><strong>Date:</strong> October 29, 2011</p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's 2nd Tree Planting Event</h4>
						<p><strong>Date:</strong> October 22, 2011</p>
						<a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/chidaes/media_set/?set=a.1662636360686532">See photos on Facebook</a>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Raffle Draw</h4>
						<p><strong>Date:</strong> August 20, 2011</p>
					</div>
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> May 21, 2011</p>
					</div>

					<SectionTitle title="2009 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Lucky Draw</h4>
						<p><strong>Date:</strong> November 21, 2009</p>
					</div>

					<SectionTitle title="2008 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> December 27, 2008</p>
					</div>

					<SectionTitle title="2007 Events" />
					<div className="text-box">
						<h4 class="mt-0">ChiDAES Canada's Annual Gathering</h4>
						<p><strong>Date:</strong> December 29, 2007</p>
					</div>
				</div>
			</div>
		);	
	}
}

export default PastEvents;