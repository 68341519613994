import React,  { Component } from 'react';
import './Contact.scss';

const axios = require('axios').default;

class Contact extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	name: '',
	    	email: '',
	    	phone: '',
	    	message: '',
	    	error: '',
	    	success: false,
	    	validname: true,
	    	validemail: true,
	    	validmessage: true,
	    	processing: false
	    };

	    this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const field = event.target.name;
		const validfield = "valid" + event.target.name;
		this.setState({
			[field]: event.target.value,
			[validfield]: true
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({ 
			error: '', 
			success: false,
			processing: true
		});

		const error = this.validate(this.state.name, this.state.email, this.state.message);

		if (error === false){
			this.sendForm(this.state.name, this.state.email, this.state.phone, this.state.message);
		}
	}

	validate = (name, email, message) => {
		let error = false;

		if (name === '') {
			this.setState({ 
				validname: false, 
				error: 'Please fill all the required fields',
				processing: false 
			});
			error = true;
		}
		if (email === ''){
			this.setState({ 
				validemail: false, 
				error: 'Please fill all the required fields',
				processing: false  
			});
			error = true;
		}
		if (message === ''){
			this.setState({ 
				validmessage: false, 
				error: 'Please fill all the required fields',
				processing: false  
			});
			error = true;
		}

		return error;
	}

	sendForm = (name, email, phone, message) => {
		axios({
            method: "POST", 
            url:"https://chiades-mailer-app.herokuapp.com/chidaes", 
            data: {
                name: name,   
                email: email,
                phone: phone,  
                message: message
            }
        }).then((response)=>{
            if (response.data.status === 'success'){
                this.resetForm();
            }
            else if(response.data.status === 'fail'){
                this.showSubmitError();
            }
        }).catch((error)=>{
        	this.showSubmitError();
		});
	}

	resetForm(){
        this.setState({
        	name: '', 
        	email: '', 
        	phone: '',
        	message: '',
        	success: true,
        	processing: false
        })
    }

    showSubmitError(){
    	this.setState({ 
			error: 'Failed to send message. Please try again.', 
			processing: false
		});
    }

	render () {
		return (
			<div>
				<div className="page-header contact-header">
					<div className="overlay">
						<h1>Contact</h1>
					</div>
				</div>
				<div className="content">
					<div className="cols contact">
						<div>
							<h4>Please fill out the form below to contact ChiDAES Canada.</h4>
							<div className="section-break"></div>
							<form id="contact-form" onSubmit={this.handleSubmit}>
								{ this.state.error && <div className="form-errors">{this.state.error}</div>}
								{ this.state.success && <div className="form-success">Message sent</div>}
								<label>Name *</label>
								<input name="name" className={this.state.validname ? '' : 'input-error'} type="text" value={this.state.name} onChange={this.handleChange}/>
								<label>Email *</label>
								<input name="email" className={this.state.validemail ? '' : 'input-error'} type="email" value={this.state.email} onChange={this.handleChange}/>
								<label>Phone</label>
								<input name="phone" type="tel" value={this.state.phone} onChange={this.handleChange} />
								<label>Message *</label>
								<textarea name="message" className={this.state.validmessage ? '' : 'input-error'} value={this.state.message} onChange={this.handleChange} rows="8"/>
								<input className="btn" type="submit" value={this.state.processing? 'Please wait...' : 'Submit'} disabled={this.state.processing} />
							</form>
							* required
							<div className="section-break"></div>
						</div>
						<div className="contact-info">
							<div className="text-box">
								<h4>Mailing Address</h4>
								<p>12 Calgary Gardens<br />
								Woodbridge, Ontario, Canada<br />
								L4L 8B2</p>
								<h4>Email</h4>
								<p><a href="mailto:ChiDAES.Canada@gmail.com">ChiDAES.Canada@gmail.com</a></p>
								<h4>Connect</h4>
								<a rel="noopener noreferrer" href="https://www.facebook.com/chidaes" target="_blank" ><i className="fab fa-facebook-square"></i><span className="connect">facebook.com/chidaes</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;