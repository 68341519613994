import React,  { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import './Nav.scss';


class MobileNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showMobileSubMenu: "",
		  showMobileMenu: this.props.showMenu
		};

		this.handleMobileMenu = this.handleMobileMenu.bind(this);
	}

	handleMobileClick = (e) => {
		e.preventDefault();
		const ID = e.target.closest("a").id;

		if (ID === "about" && this.state.showMobileSubMenu === '')
			this.setState({ showMobileSubMenu: ID });
		else 
			this.setState({ showMobileSubMenu: '' });
	};

	handleMobileMenu() {
		const val = !this.state.showMobileMenu;
		this.setState({
			showMobileMenu: val
		})
		this.props.parentCallback(false);
	}

	render () {
		return (
			<div>
				{ this.state.showMobileMenu && <div className={this.state.showMobileMenu? 'mobile-nav-items active' : 'mobile-nav-items'}>
					<li>
						<NavLink activeClassName="is-active" className="nav-item" exact={true} to="/" onClick={this.handleMobileMenu}><span>Home</span></NavLink>
					</li>
					<li>
						<NavLink  activeClassName="is-active" className="nav-item nav-item-has-child" to="/about">
							<span onClick={this.handleMobileMenu}>About</span>
						</NavLink>
						<Link to="" id="about" className="sub-nav-arrow" onClick={this.handleMobileClick}><i className={this.state.showMobileSubMenu === 'about'? 'fas fa-angle-up': 'fas fa-angle-down'}></i></Link>
						{ this.state.showMobileSubMenu === "about" && <ul className="sub-nav">
							<li>
								<Link className="nav-item" to="/about/committee-members" onClick={this.handleMobileMenu}>Committee Members</Link>
							</li>
						</ul>}
					</li>
					<li>
						<NavLink activeClassName="is-active" className="nav-item" to="/events" onClick={this.handleMobileMenu}><span>Events</span></NavLink>
					</li>
					<li>
						<NavLink activeClassName="is-active" className="nav-item" to="/projects" onClick={this.handleMobileMenu}><span>Projects</span></NavLink>
					</li>
					<li>
						<NavLink activeClassName="is-active" className="nav-item" to="/get-involved" onClick={this.handleMobileMenu}><span>Get Involved</span></NavLink>
					</li>
					<li>
						<NavLink activeClassName="is-active" className="nav-item" to="/contact" onClick={this.handleMobileMenu}><span>Contact</span></NavLink>
					</li>
				</div>
				}
			</div>
		);
	}
}

export default MobileNav;