import React,  { Component } from 'react';
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import constitution from "../../documents/ChiDAES-Constitution.pdf";

class About extends Component {
	render () {
		return (
			<div>
				<div className="page-header about-header">
					<div className="overlay">
						<h1>About</h1>
					</div>
				</div>
				<div className="content">
					<SectionTitle title="Who We Are" />
					<p>ChiDAES Canada (Children Development Association of Eastern Sri Lanka – Canada) is a not-for-profit organization registered under the jurisdiction of Ontario, Canada. In 2005, ChiDAES Canada was established with the help of like-minded individuals to help support children in need who live in the Batticaloa, Trincomalee, and the Digamadulla districts of Eastern Sri Lanka. These children have been affected by the civil war and natural disasters in that region such as the tsunami on December 26, 2004.</p>
					<p>Our goal is to help provide a better quality of life for these children in the following ways: </p>
					<ul>
						<li>Living conditions</li>
						<li>Food</li>
						<li>Education</li>
						<li>Healthcare</li>
						<li>Economic viability</li>
					</ul>
					<SectionTitle title="How We Do It" />
					<p>We raise a large portion of our funds from our members and from donations by our kind-hearted well-wishers, members, and from people just like you! We also rally support by coordinating fundraising events (such as benefit shows and raffles) to keep our projects going and to implement new ones in the future.</p>
					<SectionTitle title="A Trusted Organization" />
					<p>100% of ChiDAES Canada's total operating expenses are used for programs that benefit children in need. Currently, we are helping the Hari Children's Home in Kallady.</p>
					<p>ChiDAES Canada ensures that it abides to work under the provisions of UNICEF.</p>
					<p>View our <Link to="/about/committee-members">committee members</Link>.</p>
					<p>View our <a rel="noopener noreferrer" target="_blank" href={constitution}>constitution</a>.</p>					
				</div>
			</div>
		);
	}
}

export default About;