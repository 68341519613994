import React,  { Component } from 'react';
import './Home.scss';
import { Link } from "react-router-dom";

class Home extends Component {
	render () {
		return ( 
			<div>
				<div className="hero">
					<div className="overlay"></div>
				</div>
				<div className="content">
					<div className="hp-text text-lg">
						ChiDAES Canada (Children Development Association of Eastern Sri Lanka – Canada) is a <strong>non-profit organization</strong> registered under the jurisdiction of Ontario, Canada.
					</div>
					{/*<div className="section-break"></div>
					<h3>Latest News</h3>
					<div className="text-box border-left">
						<h4 className="colored">Annual Tree Planting Event</h4>
						<p><strong>Date:</strong> June 13, 2020 from 10:00AM to 12:30PM <br />
						<strong>Location:</strong> Mississauga</p>
					</div>*/}
					<div className="section-break"></div>
					<div className="cols">
						<Link to="/events" className="photo-box-wrapper photo-box-wrapper-dark">
							<div className="photo-box photo-box events"></div>
							<div className="overlay">
								<div className="photo-box-title">Latest Events <hr /></div>
								<div className="section-break"></div>
								<div className="photo-box-text">
									Get the full scoop on our upcoming and past events.&nbsp;
									</div>
								
							</div>
						</Link>
						<Link to="/projects" className="photo-box-wrapper photo-box-wrapper-dark">
							<div className="photo-box projects"></div>
							<div className="overlay">
								<div className="photo-box-title">ChiDAES Projects<hr /></div>
								<div className="section-break"></div>
								<div className="photo-box-text">
									Take a look at what we're doing to help to improve the lives of children in need.&nbsp;
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;