import React,  { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import './Nav.scss';
import logo from '../../images/logo/logo.png';
import MobileNav from './MobileNav.js';


class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  showSubMenu: "",
		  showMobileSubMenu: "",
		  showMobileMenu: false 
		};

		this.handleMobileMenu = this.handleMobileMenu.bind(this);
		this.closeMobileMenu = this.closeMobileMenu.bind(this);
	}

	handleHover = (e) => {
		const ID = e.target.closest("a").id;

		if (ID === "about")
		this.setState({ showSubMenu: ID });
		
	};

	handleLeave = (e) => {
		this.setState({ showSubMenu: "" });
	};


	handleMobileMenu() {
		const val = !this.state.showMobileMenu;
		this.setState({
			showMobileMenu: val
		})
	}

	closeMobileMenu(){
		this.setState({
			showMobileMenu: false
		})
	}
  
  	callbackFunction = (childData) => {
     	this.setState({
			showMobileMenu: childData
		})
	}

	render () {
		return (
			<div className="header-space">
			<div className="header">
				<div className="content">
					<div className="header-wrapper">
						<Link to="/" onClick={this.closeMobileMenu} className="logo-wrapper">
							<img src={logo} className="logo" alt="logo"/>
						</Link>
						<div className="header-top-wrapper">
							<Link className="logo-text-wrapper" to="/" onClick={this.closeMobileMenu}>
								<div className="logo-name">
										<span className="logo-acc">ChiDAES</span>
										<span className="logo-full">Children&nbsp;Development&nbsp;Association <br />
										of Eastern Sri Lanka</span>
								</div>
							</Link>
							<div className="mobile-nav">
								<div className='mobile-nav-icon' onClick={this.handleMobileMenu}>
									<i className={this.state.showMobileMenu? 'fas fa-times' : 'fas fa-bars'}></i>
								</div>
							</div>
						</div>
						<ul className="nav">
							<li>
								<NavLink activeClassName="is-active" className="nav-item" exact={true} to="/"><span>Home</span></NavLink>
							</li>
							<li onMouseLeave={this.handleLeave}>
								<NavLink onMouseEnter={this.handleHover} id="about" activeClassName="is-active" className="nav-item" to="/about"><span>About</span></NavLink>
								{ this.state.showSubMenu === "about" && <ul className="sub-nav">
									<li>
										<Link className="nav-item" to="/about/committee-members">Committee Members</Link>
									</li>
								</ul>}
							</li>
							<li>
								<NavLink activeClassName="is-active" className="nav-item" to="/events"><span>Events</span></NavLink>
							</li>
							<li>
								<NavLink activeClassName="is-active" className="nav-item" to="/projects"><span>Projects</span></NavLink>
							</li>
							<li>
								<NavLink activeClassName="is-active" className="nav-item" to="/get-involved"><span>Get Involved</span></NavLink>
							</li>
							<li>
								<NavLink activeClassName="is-active" className="nav-item" to="/contact"><span>Contact</span></NavLink>
							</li>
						</ul>
					</div>
				</div>

				{ this.state.showMobileMenu && <MobileNav showMenu={this.state.showMobileMenu} parentCallback={this.callbackFunction} />  }
			</div>
			</div>
		);
	}
}

export default Nav;